<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import * as experienceService from "@/api/services/experience.service";
import DatePicker from "@sum.cumo/vue-datepicker";
import "@sum.cumo/vue-datepicker/dist/Datepicker.css";
import ErrorComponent from "@/components/APIError.vue";

Vue.use(VueFormulate, {
  classes: {
    element: ""
  }
});

export default {
  name: "Booking",
  components: {
    CommonLayout,
    DatePicker,
    ErrorComponent
  },
  data() {
    return {
      /******* Data Variables *******/
      todayDate: null,
      experienceId: null,
      experience: null,
      experiencePrice: null,
      experienceStartTimes: null,
      convertedStartTimes: [],
      numberOfTickets: 1,
      bookingDate: null,
      bookingTime: null,
      totalBookingPrice: null,
      /******** Operational Variables********/
      isSessionStorageAvailable: false,
      showBookingTime: false,
      showBookingDate: false,
      showErrorComponent: false,
      isbookingDateError: false,
      isNumberOfTicketError: false,
      change: { val: null, model: null, modelCopy: null },
      disabledDates: {
        to: new Date()
      }
    };
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  mounted() {
    this.getTodayDate();
    this.experienceId = this.$route.params.experienceId;

    if (this.experienceId) {
      this.checkSessionStorage();
    } else {
      this.showErrorComponent = true;
    }
  },
  methods: {
    getTodayDate() {
      let currentDateWithFormat = new Date().toJSON().slice(0, 10);
      this.today = currentDateWithFormat;
    },
    navigateContinue() {
      if (this.bookingDate) {
        if (this.numberOfTickets > 0) {
          sessionStorage.setItem("experienceId", this.experienceId);
          sessionStorage.setItem("totalBookingPrice", this.totalBookingPrice);
          sessionStorage.setItem("numberOfTickets", this.numberOfTickets);
          sessionStorage.setItem("bookingDate", this.bookingDate);
          sessionStorage.setItem("bookingTime", this.bookingTime);
          sessionStorage.setItem("experienceTitle", this.experience.experienceTitle);
          sessionStorage.setItem(
            "experienceLocation",
            this.experience.experienceDestination.destinationTitle
          );
          this.$router.push({
            name: "BookingContact",
            params: {
              experienceId: sessionStorage.getItem("experienceId")
            }
          });
        } else {
          this.isNumberOfTicketError = true;
        }
      } else {
        this.isbookingDateError = true;
      }
    },
    async getExperienceById() {
      try {
        this.experience = await experienceService.getExperiencesById(this.experienceId);
        this.experienceStartTimes = this.experience.experienceSessionStartTimes;
        this.convertSessionTime();

        if (!this.isSessionStorageAvailable) {
          this.totalBookingPrice = this.thousandSeperator(this.experience.experiencePrice);
        }
      } catch (error) {
        this.showErrorComponent = true;
      }
    },
    thousandSeperator(price) {
      return price.toLocaleString("si-LK");
    },
    calcTotPrice() {
      if (this.numberOfTickets && this.numberOfTickets > 0) {
        this.isNumberOfTicketError = false;
        let bookingPrice = this.experience.experiencePrice * parseInt(this.numberOfTickets);
        this.totalBookingPrice = this.thousandSeperator(bookingPrice);
      } else {
        this.totalBookingPrice = 0;
        this.isNumberOfTicketError = true;
      }
    },
    convertSessionTime() {
      if (this.experienceStartTimes) {
        for (let startTime in this.experienceStartTimes) {
          let split_time = this.experienceStartTimes[startTime].split(":");
          let hours = parseInt(split_time[0]);
          let mins = split_time[1];
          let converted_time = "";
          if (hours > 12) {
            hours = hours - 12;
            if (hours === 12) {
              hours = hours.toString();
              converted_time = hours
                .concat(" : ")
                .concat(mins)
                .concat(" am");
            }
            hours = hours.toString();
            converted_time = hours
              .concat(" : ")
              .concat(mins)
              .concat(" pm");
          } else {
            if (hours === 0) {
              hours = "12";
              converted_time = hours
                .concat(" : ")
                .concat(mins)
                .concat(" pm");
            }
            hours = hours.toString();
            converted_time = hours
              .concat(" : ")
              .concat(mins)
              .concat(" am");
          }
          this.convertedStartTimes.push(converted_time);
        }
      }
    },
    checkSessionStorage() {
      //if the user arrives at the booking page after leaving midway,
      //the session will be checked to see if the user wants to book the same experience as before
      //if yes, then the session storage data will be populated
      let sessionStorageId = sessionStorage.getItem("experienceId");
      if (sessionStorageId === this.experienceId) {
        this.isSessionStorageAvailable = true;
        this.numberOfTickets = sessionStorage.getItem("numberOfTickets");
        this.bookingDate = sessionStorage.getItem("bookingDate");
        this.bookingTime = sessionStorage.getItem("bookingTime");
        this.showDate = true;
        this.showTime = true;
        this.getExperienceById();
        this.totalBookingPrice = sessionStorage.getItem("totalBookingPrice");
      }
      //if no, new experience will be fetched using API call
      else {
        sessionStorage.clear();
        this.getExperienceById();
      }
    },
    showDateNow() {
      this.isActive = false;
      //retreives date object converts to string and splits it to get display format date
      let date = String(this.bookingDate).split(" ");
      let dateMonth = date[1];
      let dateDay = date[2];
      let dateYear = date[3];
      this.bookingDate = dateDay + " " + dateMonth + " " + dateYear;
      this.showDate = true;
    },
    showTimeNow() {
      this.showTime = true;
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div v-if="this.showErrorComponent">
        <ErrorComponent />
      </div>
      <div class="booking-content" v-else>
        <FormulateForm @submit="navigateContinue()">
          <div class="row">
            <div class="details-input-column animate__animated animate__fadeInLeft animate__slow">
              <h4 class="details-input-heading">{{ $t("booking.input-heading") }}</h4>
              <FormulateInput
                name="Number of Tickets"
                label="Number of Tickets"
                type="number"
                placeholder="Select number of tickets"
                v-model="numberOfTickets"
                validation="required"
                @change="calcTotPrice"
              >
                <template #label="{ label, id }">
                  <label :for="id" class="formulate-label-wrapper">
                    <em class="fas fa-user-friends formulate-icon" />
                    {{ label }}
                    <span style="color: #960505"> * </span>
                  </label>
                </template>
              </FormulateInput>
              <span
                v-if="isNumberOfTicketError"
                style="margin-top: -20px; margin-bottom: 30px; font-size: 11px; color: #960505"
              >
                Number of Tickets should be more than 0.
              </span>

              <template class="datepicker-wrapper">
                <label class="formulate-label-wrapper">
                  <em class="fas fa-calendar-day datepicker-icon" />{{
                    $t("booking.selected-date-label")
                  }}
                  <span style="color: #960505"> * </span>
                </label>
                <DatePicker
                  v-model="bookingDate"
                  class="booking-datepicker"
                  :disabledDates="disabledDates"
                  :required="true"
                  @input="showDateNow()"
                  placeholder="Select a booking date"
                  fixed-position="bottom-left"
                >
                </DatePicker>
                <span
                  v-if="isbookingDateError"
                  style="margin-top: -20px; margin-bottom: 30px; font-size: 11px; color: #960505"
                >
                  Booking Date is required.
                </span>
              </template>

              <template class="datepicker-wrapper">
                <label class="formulate-label-wrapper">
                  <em class="fa fa-clock datepicker-icon" />{{ $t("booking.selected-time-label") }}
                  <span style="color: #960505"> * </span>
                </label>
                <FormulateInput
                  name="Session Start Time"
                  v-model="bookingTime"
                  :options="convertedStartTimes"
                  type="select"
                  placeholder="Select a time"
                  validation="required"
                  @change="showTimeNow()"
                />
              </template>
            </div>

            <!--div column used for spacing-->
            <div class="space-column" />

            <div class="booking-column animate__animated animate__fadeInRight animate__slow">
              <div class="experience-details-row">
                <div class="experience-details-column">
                  <h3 class="detail-heading">{{ this.experience.experienceTitle }}</h3>
                  <div class="location-container">
                    <em class="fas fa-map-marker-alt location-icon"></em>
                    <span class="detail-location">
                      {{ this.experience.experienceDestination.destinationTitle }}
                    </span>
                  </div>
                </div>
                <div class="experience-details-price">
                  <h3 class="detail-price">
                    {{ this.totalBookingPrice }}
                  </h3>
                  <span class="tot-price-label">{{ $t("booking.currency") }}</span>
                </div>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left"
                  >{{ $t("booking.no-of-tickets") }}
                </span>
                <span class="selected-details details-right">{{ numberOfTickets }}</span>
              </div>
              <div class="experience-details-summary" v-show="showDate">
                <span class="selected-details details-left"
                  >{{ $t("booking.selected-date") }}
                </span>
                <span class="selected-details details-right">{{ bookingDate }}</span>
              </div>
              <div class="experience-details-summary" v-show="showTime">
                <span class="selected-details details-left">{{ $t("booking.start-time") }} </span>
                <span class="selected-details details-right">{{ bookingTime }}</span>
              </div>
              <div class="continue-btn-container">
                <button class="continue-btn" type="submit">
                  {{ $t("booking.continue-button") }}
                  <em class="fas fa-caret-right right-arrow-icon"></em>
                </button>
              </div>
            </div>
          </div>
        </FormulateForm>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "../styles/views/_booking.scss";
@import "../../node_modules/@sum.cumo/vue-datepicker/src/styles/style.scss";
</style>
