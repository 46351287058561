<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import * as paymentService from "../api/services/payment.service";
import * as bookingService from "../api/services/booking.service";
import moment from "moment";

Vue.use(VueFormulate, {
  classes: {
    element: ""
  }
});

export default {
  name: "BookingPayment",
  components: {
    CommonLayout
  },
  data() {
    return {
      /******* Data Variables *****/
      experienceId: null,
      experienceTitle: null,
      experienceLocation: null,
      firstName: null,
      lastName: null,
      email: null,
      contactNumber: null,
      numberOfTickets: null,
      bookingDate: null,
      bookingTime: null,
      totalBookingPrice: 0,
      isAcceptTerms: false,
      countryCode: null,
      /******* Operational Variables *****/
      showErrorComponent: true,
      cardInputValid: true,
      isCardNumberMissing: false,
      isCardExpiryMissing: false,
      isCardCVCMissing: false,
      isCardHolderMissing: false,
      /****** Stripe elements *******/
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",
      cardName: "",
      stripe: null,
      stripeError: "",
      cardCvcError: "",
      cardExpiryError: "",
      cardNumberError: "",
      setupIntent: null,
      cardElement: null,
      status: null,
      paymentLoading: false,
      showContactNumber: false,
      style: {
        base: {
          "::placeholder": {
            fontStyle: "italic"
          }
        }
      }
    };
  },
  mounted() {
    this.experienceId = this.$route.params.experienceId;
    this.getCardSetupIntent();
    this.setUpStripe();
    this.getCurrentMonth();
    this.checkSessionStorage();
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  methods: {
    setUpStripe() {
      if (window.Stripe === undefined) {
        console.error("Stripe V3 library not loaded!");
      } else {
        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY);
        this.stripe = stripe;

        this.cardElement = stripe.elements();
        this.cardCvc = this.cardElement.create("cardCvc", { style: this.style });
        this.cardExpiry = this.cardElement.create("cardExpiry", { style: this.style });
        this.cardNumber = this.cardElement.create("cardNumber", { style: this.style });

        this.cardCvc.mount("#card-cvc");
        this.cardExpiry.mount("#card-expiry");
        this.cardNumber.mount("#card-number");
      }
    },
    async getCardSetupIntent() {
      this.setupIntent = await paymentService.getCardSetupIntent();
    },
    async createToken() {
      const { setupIntent } = await this.stripe.confirmCardSetup(this.setupIntent.setupIntent, {
        payment_method: {
          card: this.cardNumber,
          billing_details: {
            name: this.cardName
          }
        }
      });
      return { paymentMethod: setupIntent.payment_method, paymentId: setupIntent.id };
    },
    navigateBack() {
      //navigates to previous booking page
      this.$router.back();
    },
    async navigateContinue() {
      //show loading component until API call is done
      this.paymentLoading = true;
      //storing the booking details in an object to send for API call
      try {
        const payment = await this.createToken();
        let bookingTime = this.bookingTime;
        bookingTime = moment(bookingTime, ["h:mm A"]).format("HH:mm");
        let bookingDetails = {
          experienceId: sessionStorage.getItem("experienceId"),
          firstName: sessionStorage.getItem("firstName"),
          lastName: sessionStorage.getItem("lastName"),
          tickets: Number(sessionStorage.getItem("numberOfTickets")),
          totPrice: Number(sessionStorage.getItem("totalBookingPrice").replace(",", "")),
          sessionTime: sessionStorage.getItem("bookingTime"),
          bookingDate: sessionStorage
            .getItem("bookingDate")
            .concat(" ")
            .concat(bookingTime),
          email: sessionStorage.getItem("email"),
          contactNumber: sessionStorage.getItem("contactNumber"),
          countryCode: sessionStorage.getItem("countryCode"),
          payment
        };

        const { s3Location } = await bookingService.createBooking(bookingDetails);

        sessionStorage.setItem("s3Location", s3Location);
        //after s3 location is set, payment status is set to success and sent to summary as a param
        this.status = "success";
        //the booking summary page will use the experience Id to check whether the user navigated from this page
        this.$router.push({
          name: "BookingSummary",
          params: {
            experienceId: this.$route.params.experienceId,
            paymentStatus: this.status
          }
        });
      } catch (error) {
        console.error(error);
        //if the payment fails
        this.status = "failed";
        //the booking summary page will use the experience Id to check whether the user navigated from this page
        this.$router.push({
          name: "BookingSummary",
          params: {
            experienceId: this.$route.params.experienceId,
            paymentStatus: this.status
          }
        });
      }
    },
    getCurrentMonth() {
      let currentMonth = new Date().toJSON().slice(0, 7);
      this.minMonth = currentMonth;
    },
    checkSessionStorage() {
      let sessionStorageId = sessionStorage.getItem("experienceId");
      if (sessionStorageId === this.experienceId) {
        this.numberOfTickets = sessionStorage.getItem("numberOfTickets");
        this.bookingDate = sessionStorage.getItem("bookingDate");
        this.bookingTime = sessionStorage.getItem("bookingTime");
        this.totalBookingPrice = sessionStorage.getItem("totalBookingPrice");
        this.firstName = sessionStorage.getItem("firstName");
        this.lastName = sessionStorage.getItem("lastName");
        this.email = sessionStorage.getItem("email");
        this.contactNumber = sessionStorage.getItem("contactNumber");
        this.countryCode = sessionStorage.getItem("countryCode");
        this.isAcceptTerms = sessionStorage.getItem("isAcceptTerms");
        this.experienceTitle = sessionStorage.getItem("experienceTitle");
        this.experienceLocation = sessionStorage.getItem("experienceLocation");
        //checks whether a contact number is available to display
        if (this.contactNumber.length < 1) {
          this.showContactNumber = false
        } else {
          this.showContactNumber = true;
        }
      } else {
        this.$router.push({
          name: "ExperienceDetail",
          params: {
            experienceId: this.$route.params.experienceId
          }
        });
      }
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="booking-content">
        <FormulateForm @submit="navigateContinue()">
          <div class="row">
            <div class="details-input-column animate__animated animate__fadeInLeft animate__slow">
              <h4 class="details-input-heading">Add Payment Details</h4>
              <form>
                <div class="group">
                  <label>
                    <span
                      ><em class="fas fa-credit-card formulate-icon" />Card number
                      <span style="color: #960505"> * </span>
                    </span>
                    <div id="card-number" class="field"></div>
                    <span
                      v-if="isCardNumberMissing"
                      style="font-size: 11px; color: #960505; font-weight: 400"
                    >
                      {{ $t("booking.terms-conditions-alert") }}
                    </span>
                  </label>
                  <label>
                    <span
                      ><em class="fas fa-calendar-day formulate-icon" />Expiry date
                      <span style="color: #960505"> * </span>
                    </span>
                    <div id="card-expiry" class="field"></div>
                    <span
                      v-if="isCardExpiryMissing"
                      style="font-size: 11px; color: #960505; font-weight: 400"
                    >
                      {{ $t("booking.terms-conditions-alert") }}
                    </span>
                  </label>
                  <label>
                    <span
                      ><em class="fas fa-lock formulate-icon" />CVC
                      <span style="color: #960505"> * </span>
                    </span>
                    <div id="card-cvc" class="field"></div>
                    <span
                      v-if="isCardCVCMissing"
                      style="font-size: 11px; color: #960505; font-weight: 400"
                    >
                      {{ $t("booking.terms-conditions-alert") }}
                    </span>
                  </label>
                  <label>
                    <span
                      ><em class="fas fa-user-circle formulate-icon" />Name of card holder
                      <span style="color: #960505"> * </span></span
                    >
                    <input
                      id="card-name"
                      name="cardName"
                      v-model="cardName"
                      class="field"
                      placeholder="Card Holder name"
                    />
                    <span
                      v-if="isCardHolderMissing"
                      style="font-size: 11px; color: #960505; font-weight: 400"
                    >
                      {{ $t("booking.terms-conditions-alert") }}
                    </span>
                  </label>
                </div>
              </form>
            </div>

            <!--div column used for spacing-->
            <div class="space-column" />

            <div class="booking-column animate__animated animate__fadeInRight animate__slow">
              <div class="experience-details-row">
                <div class="experience-details-column">
                  <h3 class="detail-heading">{{ experienceTitle }}</h3>
                  <div class="location-container">
                    <em class="fas fa-map-marker-alt location-icon"></em>
                    <span class="detail-location">{{ experienceLocation }}</span>
                  </div>
                </div>
                <div class="experience-details-price">
                  <h3 class="detail-price">{{ totalBookingPrice }}</h3>
                  <span class="tot-price-label">{{ $t("booking.currency") }}</span>
                </div>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.no-of-tickets") }}</span>
                <span class="selected-details details-right">{{ numberOfTickets }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.selected-date") }}</span>
                <span class="selected-details details-right">{{ bookingDate }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.start-time") }}</span>
                <span class="selected-details details-right">{{ bookingTime }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.firstname") }}</span>
                <span class="selected-details details-right">{{ firstName }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.lastname") }}</span>
                <span class="selected-details details-right">{{ lastName }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.email") }}</span>
                <span class="selected-details details-right">{{ email }}</span>
              </div>
              <div class="experience-details-summary" v-if="this.showContactNumber">
                <span class="selected-details details-left">{{
                  $t("booking.contact-number")
                }}</span>
                <span class="selected-details details-right"
                  >+{{ countryCode }} {{ contactNumber }}</span
                >
              </div>
              <div v-if="paymentLoading" class="payment-loading-container">
                <div class="processing-txt">{{ $t("booking.processing-text") }}</div>
                <div class="dot-flashing"></div>
              </div>
              <div v-else class="continue-btn-container">
                <button class="reduced-btn back-btn-color" type="button" @click="navigateBack">
                  <em class="fas fa-caret-left left-arrow-icon"></em>
                  {{ $t("booking.back-button") }}
                </button>
                <button class="reduced-btn" type="submit">
                  {{ $t("booking.continue-button") }}
                  <em class="fas fa-caret-right right-arrow-icon"></em>
                </button>
              </div>
            </div>
          </div>
        </FormulateForm>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "@/styles/views/_booking.scss";

label {
  position: relative;
  color: $txt-black;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1.5em;
}

.formulate-icon {
  padding-right: 4px;
}

.field {
  background: $white;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-sizing: border-box;
  padding: 0.75em;
  display: block;
  width: 100%;
  margin: 0;
}

.field::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Poppins;
  font-style: italic;
}

.field::-moz-placeholder {
  font-size: 14px;
  font-family: Poppins;
  font-style: italic;
}
.field:focus-visible {
  border: solid 1px red !important;
}
</style>
