<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import VueCountryCode from "vue-country-code-select";
import * as experienceService from "@/api/services/experience.service";
Vue.use(VueCountryCode);

Vue.use(VueFormulate, {
  classes: {
    element: ""
  }
});

export default {
  name: "BookingContact",
  components: {
    CommonLayout
  },
  data() {
    return {
      /******* Data variables *******/
      experienceId: null,
      experience: null,
      firstName: null,
      lastName: null,
      email: null,
      contactNumber: null,
      numberOfTickets: null,
      bookingDate: null,
      bookingTime: null,
      totalBookingPrice: 0,
      isAcceptTerms: false,
      /******* Operational Variables *******/
      isAgreementError: false,
      countryCode: null,
      submitted: false,
      countryCodeString:'LK'
    };
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  mounted() {
    this.experienceId = this.$route.params.experienceId;

    if (this.experienceId) {
      this.getExperienceById(this.experienceId);
      this.checkSessionStorage();
    } else {
      this.showErrorComponent = true;
    }
  },
  methods: {
    navigateBack() {
      if (this.firstName) {
        sessionStorage.setItem("firstName", this.firstName);
      }
      if (this.lastName) {
        sessionStorage.setItem("lastName", this.lastName);
      }
      if (this.email) {
        sessionStorage.setItem("email", this.email);
      }
      if (this.contactNumber) {
        sessionStorage.setItem("contactNumber", this.contactNumber);
      }
      sessionStorage.setItem("countryCode", this.countryCode);
      //navigates to previous booking page
      this.$router.back();
    },
    async getExperienceById(experienceId) {
      try {
        this.experience = await experienceService.getExperiencesById(experienceId);
      } catch (error) {
        this.showErrorComponent = true;
      }
    },
    navigateContinue() {
      this.submitted = true;
      if (this.isAcceptTerms) {
        sessionStorage.setItem("firstName", this.firstName);
        sessionStorage.setItem("lastName", this.lastName);
        sessionStorage.setItem("email", this.email);
        sessionStorage.setItem("contactNumber", this.contactNumber);
        sessionStorage.setItem("countryCode", this.countryCode);
        sessionStorage.setItem("countryCodeString", this.countryCodeString);
        sessionStorage.setItem("isAcceptTerms", this.isAcceptTerms);
        this.$router.push({
          name: "BookingPayment",
          params: {
            experienceId: this.experienceId
          }
        });
      } else {
        //show alert to agree to terms and conditions
        this.isAgreementError = true;
      }
    },
    checkSessionStorage() {
      //if session contains data, it will be populated on the interface
      let sessionStorageId = sessionStorage.getItem("experienceId");
      if (sessionStorageId === this.experienceId) {
        this.numberOfTickets = sessionStorage.getItem("numberOfTickets");
        this.bookingDate = sessionStorage.getItem("bookingDate");
        this.bookingTime = sessionStorage.getItem("bookingTime");
        this.totalBookingPrice = sessionStorage.getItem("totalBookingPrice");
        this.firstName = sessionStorage.getItem("firstName");
        this.lastName = sessionStorage.getItem("lastName");
        this.email = sessionStorage.getItem("email");
        this.contactNumber = sessionStorage.getItem("contactNumber");
        this.countryCodeString = sessionStorage.getItem("countryCodeString");
        this.isAcceptTerms = sessionStorage.getItem("isAcceptTerms");
      } else {
        this.$router.push({
          name: "ExperienceDetail",
          params: {
            experienceId: this.$route.params.experienceId
          }
        });
      }
    },
    onCountrySelect({ iso2, dialCode }) {
      this.countryCodeString = iso2;
      this.countryCode = dialCode;
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="booking-content">
        <FormulateForm @submit="navigateContinue()">
          <div class="row">
            <div class="details-input-column animate__animated animate__fadeInLeft animate__slow">
              <h4 class="details-input-heading">{{ $t("booking.contact-heading") }}</h4>
              <div class="detail-row">
                <div class="detail-col">
                  <FormulateInput
                    label="First Name"
                    type="text"
                    ref="firstNameField"
                    placeholder="First Name"
                    validation="required"
                    v-model="firstName"
                  >
                    <template #label="{ label, id }">
                      <label :for="id" class="formulate-label-wrapper">
                        <i class="fas fa-user-edit formulate-icon"></i>
                        {{ label }} <span style="color: #960505"> * </span>
                      </label>
                    </template>
                  </FormulateInput>
                </div>

                <!--used for spacing in web layout-->
                <div class="space-col"></div>
                <div class="detail-col">
                  <FormulateInput
                    label="Last Name"
                    type="text"
                    ref="lastNameField"
                    placeholder="Last Name"
                    validation="required"
                    v-model="lastName"
                    input-class="sdfh"
                  >
                    <template #label="{ label, id }">
                      <label :for="id" class="formulate-label-wrapper">
                        <em class="fas fa-user-edit formulate-icon"></em>
                        {{ label }} <span style="color: #960505"> * </span>
                      </label>
                    </template>
                  </FormulateInput>
                </div>
              </div>
              <FormulateInput
                label="Email"
                type="email"
                ref="emailField"
                placeholder="Email"
                validation="required|email"
                v-model="email"
              >
                <template #label="{ label, id }">
                  <label :for="id" class="formulate-label-wrapper">
                    <em class="fas fa-envelope formulate-icon"></em>
                    {{ label }} <span style="color: #960505"> * </span>
                  </label>
                </template>
              </FormulateInput>
              <label class="formulate-label-wrapper">
                <em class="fas fa-phone-alt datepicker-icon" />Contact Number
              </label>
              <div class="country-select-wrapper">
                <template>
                  <vue-country-code
                    class="country-code-selector"
                    enableSearchField="true"
                    enabledCountryCode="true"
                    :defaultCountry="countryCodeString"
                    disabledFetchingCountry
                    @onSelect="onCountrySelect"
                  >
                  </vue-country-code>
                </template>
                <FormulateInput
                  type="number"
                  min="0"
                  validation="number|min:0"
                  ref="contactField"
                  placeholder="Contact Number"
                  v-model="contactNumber"
                  :validation-messages="{
                    min: 'Please enter a valid contact number'
                  }"
                >
                </FormulateInput>
              </div>
              <div class="checkbox-wrapper">
                <input type="checkbox" v-model="isAcceptTerms" />
                <label for="checkbox" class="checkbox-text">
                  {{ $t("booking.terms-conditions-txt1") }}
                  <router-link to="/terms&conditions" target="_blank">
                    {{ $t("booking.terms-conditions-txt2") }} </router-link
                  >
                </label>
              </div>
              <div v-if="(submitted) && (!isAcceptTerms)">
                <span
                  style="margin-top: -20px; margin-bottom: 30px; font-size: 11px; color: #960505"
                >
                  {{ $t("booking.terms-conditions-alert") }}
                </span>
              </div>
            </div>

            <!--div column used for spacing-->
            <div class="space-column" />

            <div class="booking-column animate__animated animate__fadeInRight animate__slow">
              <div class="experience-details-row">
                <div class="experience-details-column">
                  <h3 class="detail-heading">{{ experience.experienceTitle }}</h3>
                  <div class="location-container">
                    <em class="fas fa-map-marker-alt location-icon"></em>
                    <span class="detail-location">{{
                      experience.experienceDestination.destinationTitle
                    }}</span>
                  </div>
                </div>
                <div class="experience-details-price">
                  <h3 class="detail-price">
                    {{ totalBookingPrice }}
                  </h3>
                  <span class="tot-price-label">{{ $t("booking.currency") }}</span>
                </div>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.no-of-tickets") }}</span>
                <span class="selected-details details-right">{{ numberOfTickets }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.selected-date") }}</span>
                <span class="selected-details details-right">{{ bookingDate }}</span>
              </div>
              <div class="experience-details-summary">
                <span class="selected-details details-left">{{ $t("booking.start-time") }}</span>
                <span class="selected-details details-right">{{ bookingTime }}</span>
              </div>
              <div class="continue-btn-container">
                <button class="reduced-btn back-btn-color" type="button" @click="navigateBack">
                  <em class="fas fa-caret-left left-arrow-icon"></em>{{ $t("booking.back-button") }}
                </button>
                <button class="reduced-btn" type="submit">
                  {{ $t("booking.continue-button") }}
                  <em class="fas fa-caret-right right-arrow-icon"></em>
                </button>
              </div>
            </div>
          </div>
        </FormulateForm>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "../styles/views/_booking.scss";

.country-select-wrapper {
  display: flex;
}

.country-code-selector {
  padding: 0.1em;
  max-height: 2.5em;
}

</style>
