import { ApiCall } from "../apiBase";

export async function getCardSetupIntent() {
  let setupIntent = null;

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query {
              getCardSetupIntent {
                setupIntent
              }
            }
            `
    }
  });

  if (!result.data.errors && result.data.data.getCardSetupIntent) {
    setupIntent = result.data.data.getCardSetupIntent;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return setupIntent;
}
