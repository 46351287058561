<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";

Vue.use(VueFormulate, {
  classes: {
    element: ""
  }
});

export default {
  name: "Booking Summary",
  components: {
    CommonLayout
  },
  data() {
    return {
      bookingDate: null,
      price: null,
      experienceId: null,
      experienceName: null,
      experienceLocation: null,
      noOfTickets: null,
      s3Location: null,
      firstName: null,
      lastName: null,
      email: null,
      contactNumber: null,
      countryCode: null,
      bookingTime: null,
      paymentSuccess: false,
      paymentFailed: false,
      paymentSuccessMobile: false,
      showContactNumber: false
    };
  },
  created() {
    this.checkDataAvailability();
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  mounted() {
    this.checkSessionStorage();
    this.checkPaymentStatus();
  },
  methods: {
    //checks whether the user naviagted from the experiences page
    checkDataAvailability() {
      let id = sessionStorage.getItem("experienceId");
      if (!id) {
        this.$router.push({
          name: "ExperienceDetail",
          params: {
            experienceId: this.$route.params.experienceId
          }
        });
      }
    },
    //checks session storage for saved data
    checkSessionStorage() {
      let id = sessionStorage.getItem("experienceId");
      //if session contains data, it will be populated on the interface
      if (id) {
        this.price = sessionStorage.getItem("totalBookingPrice");
        this.noOfTickets = sessionStorage.getItem("numberOfTickets");
        this.experienceName = sessionStorage.getItem("experienceTitle");
        this.experienceLocation = sessionStorage.getItem("experienceLocation");
        this.bookingDate = sessionStorage.getItem("bookingDate");
        this.bookingTime = sessionStorage.getItem("bookingTime");
        this.s3Location = sessionStorage.getItem("s3Location");
        this.firstName = sessionStorage.getItem("firstName");
        this.lastName = sessionStorage.getItem("lastName");
        this.email = sessionStorage.getItem("email");
        this.contactNumber = sessionStorage.getItem("contactNumber");
        this.countryCode = sessionStorage.getItem("countryCode");
        //checks whether a contact number is available to display
        if (this.contactNumber.length < 1) {
          this.showContactNumber = false;
        } else {
          this.showContactNumber = true;
        }
      }
    },
    //checks the status of the payment
    checkPaymentStatus() {
      let paymentStatus = this.$route.params.paymentStatus;

      if (paymentStatus === "success" && window.innerWidth <= 767) {
        this.paymentSuccessMobile = true;
        this.paymentFailed = false;
        this.paymentSuccess = false;
        //session storage will be cleared if the payment was successful
        sessionStorage.clear();
      } else {
        if (paymentStatus === "success") {
          this.paymentSuccess = true;
          //session storage will be cleared if the payment was successful
          sessionStorage.clear();
        } else {
          //session storage is kept intact so that the user can navigate back to payment page
          this.paymentFailed = true;
        }
      }
    },
    //navigates back to payment page
    navigateBack() {
      //navigates to previous booking page
      this.$router.back();
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="booking-content">
        <div class="row">
          <div class="details-input-column animate__animated animate__fadeInLeft animate__slow">
            <div class="summary-txt-content">
              <div
                class="booking-summary-icon animate__animated animate__pulse animate__fast animate__infinite"
              >
                <img
                  v-show="paymentSuccess || paymentSuccessMobile"
                  alt="booking-status-image"
                  src="../assets/icons/tick.svg"
                />
                <img
                  v-show="paymentFailed"
                  alt="booking-status-image"
                  src="../assets/icons/error.svg"
                />
              </div>
              <div class="booking-successful-txt">
                <span v-show="paymentSuccess || paymentSuccessMobile">{{
                  $t("booking.booking-successful")
                }}</span>
                <span v-show="paymentFailed">{{ $t("booking.booking-failed") }}</span>
              </div>
              <div class="booking-description">
                <p v-show="paymentSuccess || paymentSuccessMobile">
                  {{ $t("booking.booking-successful-description") }}
                </p>
                <p v-show="paymentFailed">
                  <span>{{ $t("booking.booking-failed-description1") }}</span
                  ><br />
                  <span>{{ $t("booking.booking-failed-description2") }}</span>
                  <a href="/contact_us" class="hyperlink" target="_blank"> contact us</a> for help
                </p>
              </div>
              <div class="continue-btn-container">
                <button v-show="paymentSuccessMobile" class="continue-btn">
                  <a
                    download="Ticket.pdf"
                    :href="s3Location"
                    target="blank"
                    class="continue-btn-link"
                    >{{ $t("booking.download-ticket-button") }}</a
                  >
                </button>
              </div>
            </div>
          </div>

          <!--div column used for spacing-->
          <div class="space-column" />

          <div class="booking-column animate__animated animate__fadeInRight animate__slow">
            <div class="experience-details-row">
              <div class="experience-details-column">
                <h3 class="detail-heading">{{ experienceName }}</h3>
                <div class="location-container">
                  <i class="fas fa-map-marker-alt location-icon"></i>
                  <span class="detail-location">{{ experienceLocation }}</span>
                </div>
              </div>
              <div class="experience-details-price">
                <h3 class="detail-price">{{ $t("booking.currency") }} {{ price }}</h3>
                <span class="tot-price-label">{{ $t("booking.currency") }}</span>
              </div>
            </div>
            <div class="experience-details-summary">
              <span class="selected-details details-left">{{ $t("booking.no-of-tickets") }}</span>
              <span class="selected-details details-right">{{ noOfTickets }}</span>
            </div>
            <div class="experience-details-summary">
              <span class="selected-details details-left">{{ $t("booking.selected-date") }}</span>
              <span class="selected-details details-right">{{ bookingDate }}</span>
            </div>
            <div class="experience-details-summary">
              <span class="selected-details details-left">{{ $t("booking.start-time") }}</span>
              <span class="selected-details details-right">{{ bookingTime }}</span>
            </div>
            <div class="experience-details-summary">
              <span class="selected-details details-left">{{ $t("booking.firstname") }}</span>
              <span class="selected-details details-right">{{ firstName }}</span>
            </div>
            <div class="experience-details-summary">
              <span class="selected-details details-left">{{ $t("booking.lastname") }}</span>
              <span class="selected-details details-right">{{ lastName }}</span>
            </div>
            <div class="experience-details-summary">
              <span class="selected-details details-left">{{ $t("booking.email") }}</span>
              <span class="selected-details details-right">{{ email }}</span>
            </div>
            <div class="experience-details-summary" v-if="this.showContactNumber">
              <span class="selected-details details-left">{{ $t("booking.contact-number") }}</span>
              <span class="selected-details details-right"
                >+{{ countryCode }} {{ contactNumber }}</span
              >
            </div>
            <div class="continue-btn-container">
              <button v-show="paymentSuccess" class="continue-btn">
                <a
                  download="Ticket.pdf"
                  :href="s3Location"
                  target="blank"
                  class="continue-btn-link"
                  >{{ $t("booking.download-ticket-button") }}</a
                >
              </button>
              <button
                v-show="paymentFailed"
                class="continue-btn back-btn-color"
                @click="navigateBack"
              >
                <em class="fas fa-caret-left left-arrow-icon"></em>
                {{ $t("booking.back-button") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "../styles/views/_booking.scss";
</style>
