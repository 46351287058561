import { ApiCall } from "../apiBase";

/******** Create Booking ********/
export async function createBooking(newBooking) {
  let responseStatus = null;

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
          mutation addReservation($reservation : CreateReservationInput!){
              addReservation(reservation: $reservation)
            {
              s3Location
              status
            }
          }
        `,
      variables: {
        reservation: {
          ticketCount: newBooking.tickets,
          firstName: newBooking.firstName,
          lastName: newBooking.lastName,
          bookingDate: newBooking.bookingDate,
          email: newBooking.email,
          contactNumber: newBooking.contactNumber,
          experienceId: newBooking.experienceId,
          totalPrice: newBooking.totPrice,
          countryCode: newBooking.countryCode,
          payment: newBooking.payment
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.addReservation) {
    responseStatus = result.data.data.addReservation;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }
  return responseStatus;
}
